<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23 22V9H14V22H23Z" stroke="currentColor" stroke-width="1.5" />
    <path d="M17.75 19H19.25" stroke="currentColor" stroke-width="1.5" />
    <path
      d="M20 6.5V2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19.5"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M11.5 22H4.5C3.83696 22 3.20107 21.7366 2.73223 21.2678C2.26339 20.7989 2 20.163 2 19.5C2 18.837 2.26339 18.2011 2.73223 17.7322C3.20107 17.2634 3.83696 17 4.5 17H11.5"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
